<template>
  <div class="thickness">
    <div class="title">{{ $t('message.sectorFastener.isolationThickness.title') }}</div>
    <div class="input">
      <plus-minus-input :min="0" :value="thickness" :step="10" @updateValue="updateThickness" />
    </div>
  </div>
</template>

<script>
import PlusMinusInput from '@/components/dump/PlusMinusInput'
export default {
  props: {
    thickness: {
      type: Number,
      default: 0,
      required: true
    }
  },
  name: 'InsulationThickness',
  components: { PlusMinusInput },
  methods: {
    updateThickness(val) {
      this.$emit('updateThickness', val)
    }
  }
}
</script>

<style scoped lang="sass">
.thickness
  text-align: left
  display: flex
  flex-direction: column
  align-items: flex-start
  .title
    @extend %title-mini
    margin-bottom: rem(16)
</style>
