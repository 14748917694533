<template>
  <div class="plus-minus-input">
    <button
      v-if="!isInline"
      class="btn"
      @click="updateVal(actions.decrease)"
      :disabled="isMinusDisabled"
    >
      <img src="@/assets/img/minus.svg" alt="-" />
    </button>
    <input
      type="number"
      placeholder=""
      :min="min"
      :step="step"
      v-model.number="computedValue"
      class="input"
      :class="{ 'input--inline': isInline }"
    />
    <button v-if="!isInline" class="btn" @click="updateVal(actions.increase)">
      <img src="@/assets/img/plus.svg" alt="+" />
    </button>
  </div>
</template>

<script>
import { clamp, isValidInteger } from '@/utils'
const actions = {
  decrease: 'decrease',
  increase: 'increase'
}
export default {
  props: {
    value: {
      type: Number,
      default: 0,
      required: true
    },
    min: {
      type: Number,
      default: 1
    },
    isInline: Boolean,
    step: {
      type: Number,
      default: 10,
      required: true
    }
  },
  name: 'PlusMinusInput',
  methods: {
    updateVal(action) {
      let newVal = 0
      if (action === actions.decrease) {
        newVal = this.computedValue < this.step ? this.min : this.computedValue - this.step
      } else {
        newVal = this.computedValue + this.step
      }
      this.$emit('updateValue', newVal)
    }
  },
  computed: {
    actions: () => actions,
    isMinusDisabled() {
      return this.computedValue === this.min
    },
    computedValue: {
      get() {
        return clamp(this.value, this.min)
      },
      set(val) {
        const resVal = clamp(val, this.min)
        if (isValidInteger(resVal, this.min === 0)) {
          this.$emit('updateValue', val)
        }
      }
    }
  }
}
</script>

<style scoped lang="sass">
.plus-minus-input
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  .input
    background: $white
    border: rem(1) solid #C9C9C9
    box-sizing: border-box
    border-radius: rem(4)
    width: rem(68)
    height: rem(40)
    text-align: center
    outline: none
    margin: 0 rem(9)
    font-family: $main-font
    font-style: normal
    font-weight: 600
    font-size: rem(18)
    line-height: rem(22)
    color: rgba(28, 28, 27, 0.9)
    transition: .5s
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    &:hover, &:focus
      border-color: $green
    &.info
      pointer-events: none
      +media((margin: (320: 0 0 0 rem(10), 768: 0 rem(31))))
    &--inline
      text-align: left
      padding-left: rem(13)
      margin-right: rem(10)
      font-size: rem(12)
  .btn
    border: none
    box-shadow: none
    background: none
    outline: none
    cursor: pointer
    width: rem(22)
    height: rem(22)
    background: $main
    border-radius: rem(2)
    color: $white
    font-weight: 700
    font-size: rem(16)
    padding: 0
    line-height: 1
    font-family: $main-font
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    &:disabled
      background: $tn_gray
</style>
